import React from 'react'

import PageHead from 'components/head/PageHead'
import { Header, Footer } from 'components/layout'
import { AssetsSection, HeaderSection } from 'components/pages/assets'
import defaultData from 'data/pages/Contents/assets.json'

import 'static/assets/scss/styles.scss'

function StatusPage({ location, pageData = defaultData }) {
  const { content } = pageData
  const { header, assets } = content

  return (
    <div className="x__assets-page">
      <Header trackerPage="assets" location={location} />

      <main className="x">
        <HeaderSection data={header} />
        <AssetsSection data={assets} />
      </main>

      <Footer />
    </div>
  )
}

export default StatusPage

export function Head() {
  return <PageHead page="assets" nocache />
}
