import React, { forwardRef } from 'react'

import AssetIcon from 'components/assets/shared/AssetIcon'

import 'components/pages/asset-page/scss/AboutSection.scss'

function AboutSection({ asset, colors, data }, ref) {
  const checkColorsOverride = colors && colors.primary ? colors.primary : asset.colors.primary

  const { h2, h3, tags } = data

  return (
    <section ref={ref} className="x__asset-page__about">
      <div className="x__asset-page__about__content">
        <div className="x__asset-page__about__heading-wrapper">
          <AssetIcon icon={asset.icon} name={asset.name} ticker={asset.ticker} size="larger" />
          <h2 className="x__asset-page__about__heading" dangerouslySetInnerHTML={{ __html: h2 }} />
        </div>

        <h3 className="x__asset-page__about__subheading" dangerouslySetInnerHTML={{ __html: h3 }} />

        {tags && tags.length > 0 ? (
          <ul className="x__asset-page__about__tags">
            {tags.map((tag, i) => {
              const color = {
                color: checkColorsOverride,
                background: `${checkColorsOverride}15`,
              }

              return (
                <li key={i} className="x__asset-page__about__tag" style={color}>
                  {tag}
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
    </section>
  )
}

export default forwardRef(AboutSection)
