// dependencies.
import React, { useState } from 'react'
// components.
import AssetIcon from 'src/components/assets/shared/AssetIcon'
import DownloadDropdown from 'src/components/DownloadDropdown'
import PageHead from 'src/components/head/PageHead'
import Heading from 'src/components/heading/Heading'
import { Header, Footer } from 'src/components/layout'
// utils.
import processCampaigns from 'src/js/campaigns'
import { useRellax } from 'src/js/utils/hooks'
import { useReleases } from 'js/utils/hooks/useReleases'
import useFetchSimplifiedAssets from 'js/assets/hooks/useFetchSimplifiedAssets'
// constants.
import { DOWNLOADS_URL } from 'src/constants'
// data.
import ReleasesData from 'src/data/pages/Contents/releases.json'

// Styles & Images
import 'static/releases/scss/styles.scss'

// Partials:
const HeaderSection = () => {
  useRellax({ className: 'releases-rellax' })

  return (
    <header className="x__header">
      <div className="x__header-bg-container releases-rellax" data-rellax-speed="-4">
        <div />
      </div>

      <div className="x__header-content">
        <div className="x__header-txt-container x__width">
          <Heading page="releases" className="x__header-heading" />
          <Heading type="h2" page="releases" className="x__header-subheading" />
        </div>
      </div>
    </header>
  )
}

const LatestReleaseSection = ({ data, releases }) => {
  const latestRelease = releases[0]
  const newAssets = useFetchSimplifiedAssets().assets.filter((asset) => asset.new === true)

  const handleDownloadLinkClick = () => {
    processCampaigns({ eventType: 'download' })
    rdt('track', 'AddToCart') // eslint-disable-line no-undef
  }

  return (
    <div className="x__width">
      <div className="x-releases__item">
        <div className="row x-releases__latest">
          <div className="col-lg-6 col-md-12 x-releases__item-overview">
            <h3 className="x-releases__item-heading">
              {data.heading}
              <span className="x-releases__item-heading-date">{latestRelease.version}</span>
            </h3>
            <span className="x-releases__item-released">
              {data.subheading} {latestRelease.releaseDate}
            </span>
          </div>
        </div>

        <div className="row x-releases__item-overview-container row x-releases__item-latest">
          <div className="col-lg-6 col-md-12 x-releases__item-overview">
            {latestRelease.highlights.map((text, i) => (
              <p
                key={i}
                className="x-releases__item-fixes"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            ))}

            <DownloadDropdown
              variant="releases"
              trackerPage="ReleasesPage"
              onClick={() => handleDownloadLinkClick()}
            />

            <div className="x-releases__item-links">
              <a
                href={`${DOWNLOADS_URL}/hashes-exodus-${latestRelease.version}.txt`}
                target="_blank"
                rel="noopener"
              >
                {data.hashesLinkCopy}
              </a>
              <span />
              <a href="/terms" target="_blank" rel="noopener">
                {data.termsLinkCopy}
              </a>
            </div>

            <div className="x-releases__item-new">
              <p>{data.newAssets}</p>
              <div className="x-releases__item-new-images">
                {newAssets.map((data) => (
                  <AssetIcon key={data.ticker} {...data} size="larger" showTooltip />
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 x-releases__item-details">
            {latestRelease.details.map((data, index) => (
              <div
                key={index}
                className={`x-releases__item-details-point x-releases__item-details-${data.type}`}
              >
                <h4>{data.header}</h4>
                {data.paragraphs.map((text, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: text }} />
                ))}
                {data.list && (
                  <ul>
                    {data.list.map((text, i) => (
                      <li key={i} dangerouslySetInnerHTML={{ __html: text }} />
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const ReleasesSection = ({ data, releases }) => (
  <div>
    {releases.map((release, index) => {
      return (
        index > 0 && (
          <div
            key={index}
            className="x-releases__item-grouping"
            id={release.version.replace(/\./g, '-')}
          >
            <div className="x__width">
              <div className="x-releases__item">
                <div className="row">
                  <div className="col-12 x-releases__item-overview">
                    <div className="x-releases__item-heading">{release.version}</div>
                    <div className="x-releases__item-released">
                      {data.subheading} {release.releaseDate}
                    </div>
                  </div>
                </div>
                <div className="row x-releases__item-overview-container">
                  <div className="col-lg-6 col-md-12 x-releases__item-overview">
                    {release.highlights.map((text, t) => (
                      <p
                        key={t}
                        className="x-releases__item-fixes"
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6 col-md-12 x-releases__item-details">
                    {release.details.map((detail, d) => (
                      <div
                        key={d}
                        className={`x-releases__item-details-point x-releases__item-details-${detail.type}`}
                      >
                        <h4>{detail.header}</h4>
                        {detail.paragraphs.map((text, t) => (
                          <p key={t} dangerouslySetInnerHTML={{ __html: text }} />
                        ))}
                        {data.list && (
                          <ul>
                            {data.list.map((text, i) => (
                              <li key={i} dangerouslySetInnerHTML={{ __html: text }} />
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )
    })}
  </div>
)

const ShowAllSection = ({ data, onClick }) => (
  <div className="x__width">
    <div className="x-releases__view-all" id="x-releases__view-all">
      <a className="x__button" role="button" style={{ color: '#fff' }} onClick={onClick}>
        <span className="x__button-align">
          <span className="x__button-align-text">{data.copy}</span>
        </span>
      </a>
    </div>
  </div>
)

const ReleasesPage = ({ location }) => {
  const [showAll, setShowAll] = useState(false)

  const handleShowAll = (e) => {
    e.preventDefault()
    setShowAll(true)
  }

  const Releases = useReleases()

  return (
    <div className="x-page-releases">
      <Header trackerPage="releases" location={location} />

      <main className="x">
        <HeaderSection />

        <section className="x-releases">
          <LatestReleaseSection data={ReleasesData.latestRelease} releases={Releases} />

          {showAll ? (
            <ReleasesSection data={ReleasesData.releases} releases={Releases} />
          ) : (
            <ShowAllSection data={ReleasesData.showAll} onClick={handleShowAll} />
          )}
        </section>

        <Footer />
      </main>
    </div>
  )
}

export default ReleasesPage

// <head> component:
export function Head() {
  return <PageHead page="releases" nocache />
}
