// dependencies.
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import PageHead from 'src/components/head/PageHead'
import { Header, Footer } from 'src/components/layout'
import {
  AssetsSection,
  CarouselSection,
  FeaturesSection,
  HeaderSection,
  VideoSection,
} from 'components/pages/web3-wallet'
// utils.
import { useRellax, useDimensions } from 'js/utils/hooks'
// data.
import data from 'src/data/pages/Contents/web3-wallet.json'

// Styles & Images
import 'static/web3-wallet/scss/styles.scss'

// Main Component:
const BrowserExtension = ({ pageName = 'web3-wallet', pageData = data, location }) => {
  const { content } = pageData
  const { header, carousel, features, video, assets } = content

  const { height, width } = useDimensions()

  useRellax({ className: 'web3-wallet-page-rellax', center: true })

  // get images.
  const images = useStaticQuery(graphql`
    query {
      background400: file(relativePath: { regex: "/^web3-wallet\/img\/header-bg-ssize\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 400, placeholder: BLURRED) }
      }
      background800: file(relativePath: { regex: "/^web3-wallet\/img\/header-bg-msize\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 800, placeholder: BLURRED) }
      }
      background1600: file(relativePath: { regex: "/^web3-wallet\/img\/header-bg-lsize\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      featuresSlide1: file(relativePath: { regex: "/^web3-wallet\/img\/features\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      featuresSlide2: file(relativePath: { regex: "/^web3-wallet\/img\/features\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      featuresSlide3: file(relativePath: { regex: "/^web3-wallet\/img\/features\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }

      mobileSlide1: file(relativePath: { regex: "/^web3-wallet\/img\/mobile\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      mobileSlide2: file(relativePath: { regex: "/^web3-wallet\/img\/mobile\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      mobileSlide3: file(relativePath: { regex: "/^web3-wallet\/img\/mobile\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }

      showcaseSlide1: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      showcaseSlide2: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      showcaseSlide3: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
      showcaseSlide4: file(relativePath: { regex: "/^web3-wallet\/img\/showcase\/slide_4\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }

      videoPreview: file(relativePath: { regex: "/^web3-wallet\/img\/video_preview\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1040, placeholder: BLURRED) }
      }
    }
  `)

  const getBackgroundImage = () => {
    if (width && width <= 400) {
      return images.background400.childImageSharp.gatsbyImageData
    } else if (width && width <= 800) {
      return images.background800.childImageSharp.gatsbyImageData
    } else {
      return images.background1600.childImageSharp.gatsbyImageData
    }
  }

  // carousel images.
  const carouselImageMap = {
    featuresCarousel: [
      images.featuresSlide1.childImageSharp.gatsbyImageData,
      images.featuresSlide2.childImageSharp.gatsbyImageData,
      images.featuresSlide3.childImageSharp.gatsbyImageData,
    ],
    mobileCarousel: [
      images.mobileSlide1.childImageSharp.gatsbyImageData,
      images.mobileSlide2.childImageSharp.gatsbyImageData,
      images.mobileSlide3.childImageSharp.gatsbyImageData,
    ],
    showcaseCarousel: [
      images.showcaseSlide1.childImageSharp.gatsbyImageData,
      images.showcaseSlide2.childImageSharp.gatsbyImageData,
      images.showcaseSlide3.childImageSharp.gatsbyImageData,
      images.showcaseSlide4.childImageSharp.gatsbyImageData,
    ],
  }

  const carouselData = carousel.map((carouselItem) => {
    const key = Object.keys(carouselItem)[0]
    const imagesArray = carouselImageMap[key] || []
    const items =
      carouselItem[key].items.length > 0 &&
      carouselItem[key].items.map((item, i) => {
        return { ...item, image: imagesArray[i] }
      })
    return { ...carouselItem[key], items }
  })

  const prepareFeaturesData = () => {
    const items =
      features.items.length > 0 &&
      features.items.map((item, i) => {
        return { ...item, image: carouselImageMap.showcaseCarousel[i] }
      })
    return { ...features, items }
  }
  const featuresData = prepareFeaturesData()

  /* Return content */
  return (
    <div className="x__web3-wallet-page x-page-browser-extension">
      <Header trackerPage={pageName} location={location} />

      <main className="x">
        <div
          height={height}
          className="x__web3-wallet-page__background-container web3-wallet-page-rellax"
          data-rellax-speed="-4"
          role="presentation"
        >
          <div className="x__web3-wallet-page__background">
            <GatsbyImage image={getBackgroundImage()} alt={header.alt} loading="eager" />
          </div>
        </div>

        {header && <HeaderSection data={header} location={location} />}

        {carouselData.length > 0 &&
          carouselData.map((carouselItem, i) => (
            <CarouselSection key={i + 1} data={carouselItem} />
          ))}

        {featuresData && <FeaturesSection data={featuresData} />}

        {video && (
          <VideoSection
            data={{ ...video, image: images.videoPreview.childImageSharp.gatsbyImageData }}
          />
        )}

        {assets && <AssetsSection data={assets} />}

        <Footer enableSubscribeNotice={false} />
      </main>
    </div>
  )
}

export default BrowserExtension

// <head> component:
export const Head = () => <PageHead page="web3-wallet" />
